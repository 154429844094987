<template>
  <v-container fill-height class="accent3 d-flex justify-start align-start" fluid>
    <v-row justify="start" align="start" class="py-8 px-4">
      <v-card class="ma-2 pa-8 primary--text accent--text" color="accent3" width="100%" height="100%" elevation="0">
        <v-card-title class="text-h5">{{ sessiondetails.name }}'s answer sheet</v-card-title>
        <v-row class="mb-4 d-flex justify-space-between" no-gutters>
          <v-col cols="6" md="3" lg="2" class="secondary white--text">
            <v-card-title class="overline">Correct/Attempted</v-card-title>
            <v-card-subtitle class="text-h5 font-weight-bold"
              >{{ correctanswers }}/{{ totalquestions }}</v-card-subtitle
            >
          </v-col>
          <v-col cols="6" md="3" lg="2" class="success white--text">
            <v-card-title class="overline">Accuracy</v-card-title>
            <v-card-subtitle class="text-h5 font-weight-bold">{{ accuracy }}%</v-card-subtitle>
          </v-col>
          <v-col cols="6" md="3" lg="2" class="accent white--text">
            <v-card-title class="overline">Accuracy (no Powerups)</v-card-title>
            <v-card-subtitle class="text-h5 font-weight-bold">{{ accuracy_no_powerup }}%</v-card-subtitle>
          </v-col>
          <v-col cols="6" md="3" lg="2" class="accent2 secondary--text">
            <v-card-title class="overline">Total Score</v-card-title>
            <v-card-subtitle class="text-h5 font-weight-bold">{{ sessiondetails.totalscore }}</v-card-subtitle>
          </v-col>
        </v-row>
        <v-simple-table v-if="sessiondetails" class="accent3">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left accent--text overline">S.No</th>
                <th class="text-left accent--text overline">Question</th>
                <th class="text-left accent--text overline">Choices</th>
                <th class="text-left accent--text overline">Answer</th>
                <th class="text-left accent--text overline">Score</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(d, index) in session.questions" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ d.question }}</td>
                <td>
                  <v-chip
                    label
                    :outlined="!d.correctanswers[i]"
                    :color="d.correctanswers[i] ? 'success' : 'primary'"
                    :class="d.correctanswers[i] ? 'white--text' : 'primary--text'"
                    class="ma-1"
                    v-for="(answer, i) in d.answers"
                    :key="i"
                  >
                    <span>{{ answer }}</span>
                  </v-chip>
                </td>
                <!-- <td>{{ sessiondetails.details[index] ? sessiondetails.details[index].chosenanswer : "No Answer" }}</td> -->
                <td>{{ answeremoji(sessiondetails.details[index]) }}</td>
                <td>{{ sessiondetails.details[index] ? sessiondetails.details[index].score : 0 }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-row>
          <v-btn color="secondary" large @click="goBack" class="mt-4">
            <font-awesome-icon icon="arrow-left" pull="left"></font-awesome-icon>
            Back</v-btn
          >
        </v-row>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex"

export default {
  name: "answer-details",
  async created() {},
  computed: {
    ...mapGetters(["getSessionById"]),
    ...mapState({
      session: (state) => state.quiz.currentSession,
    }),
    sessiondetails() {
      return this.getSessionById(this.$route.params.id)
    },

    correctanswers() {
      return this.sessiondetails?.details?.filter((e) => e.chosenanswer !== "No Answer")?.length
    },
    totalquestions() {
      return this.sessiondetails?.details?.length
    },
    accuracy() {
      return Math.round((this.correctanswers / this.totalquestions) * 100)
    },
    accuracy_no_powerup() {
      return Math.round(((this.correctanswers - this.sessiondetails.powerupcount) / this.totalquestions) * 100)
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },

    answeremoji(answer) {
      return answer === undefined ? "⛔️" : answer.score > 0 ? "✅" : "🚫"
    },
  },
}
</script>
<style lang="scss"></style>
