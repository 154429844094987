var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "accent3 d-flex justify-start align-start",
      attrs: { "fill-height": "", fluid: "" }
    },
    [
      _c(
        "v-row",
        {
          staticClass: "py-8 px-4",
          attrs: { justify: "start", align: "start" }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "ma-2 pa-8 primary--text accent--text",
              attrs: {
                color: "accent3",
                width: "100%",
                height: "100%",
                elevation: "0"
              }
            },
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v(_vm._s(_vm.sessiondetails.name) + "'s answer sheet")
              ]),
              _c(
                "v-row",
                {
                  staticClass: "mb-4 d-flex justify-space-between",
                  attrs: { "no-gutters": "" }
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "secondary white--text",
                      attrs: { cols: "6", md: "3", lg: "2" }
                    },
                    [
                      _c("v-card-title", { staticClass: "overline" }, [
                        _vm._v("Correct/Attempted")
                      ]),
                      _c(
                        "v-card-subtitle",
                        { staticClass: "text-h5 font-weight-bold" },
                        [
                          _vm._v(
                            _vm._s(_vm.correctanswers) +
                              "/" +
                              _vm._s(_vm.totalquestions)
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "success white--text",
                      attrs: { cols: "6", md: "3", lg: "2" }
                    },
                    [
                      _c("v-card-title", { staticClass: "overline" }, [
                        _vm._v("Accuracy")
                      ]),
                      _c(
                        "v-card-subtitle",
                        { staticClass: "text-h5 font-weight-bold" },
                        [_vm._v(_vm._s(_vm.accuracy) + "%")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "accent white--text",
                      attrs: { cols: "6", md: "3", lg: "2" }
                    },
                    [
                      _c("v-card-title", { staticClass: "overline" }, [
                        _vm._v("Accuracy (no Powerups)")
                      ]),
                      _c(
                        "v-card-subtitle",
                        { staticClass: "text-h5 font-weight-bold" },
                        [_vm._v(_vm._s(_vm.accuracy_no_powerup) + "%")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "accent2 secondary--text",
                      attrs: { cols: "6", md: "3", lg: "2" }
                    },
                    [
                      _c("v-card-title", { staticClass: "overline" }, [
                        _vm._v("Total Score")
                      ]),
                      _c(
                        "v-card-subtitle",
                        { staticClass: "text-h5 font-weight-bold" },
                        [_vm._v(_vm._s(_vm.sessiondetails.totalscore))]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.sessiondetails
                ? _c("v-simple-table", {
                    staticClass: "accent3",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function() {
                            return [
                              _c("thead", [
                                _c("tr", [
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "text-left accent--text overline"
                                    },
                                    [_vm._v("S.No")]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "text-left accent--text overline"
                                    },
                                    [_vm._v("Question")]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "text-left accent--text overline"
                                    },
                                    [_vm._v("Choices")]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "text-left accent--text overline"
                                    },
                                    [_vm._v("Answer")]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "text-left accent--text overline"
                                    },
                                    [_vm._v("Score")]
                                  )
                                ])
                              ]),
                              _c(
                                "tbody",
                                _vm._l(_vm.session.questions, function(
                                  d,
                                  index
                                ) {
                                  return _c("tr", { key: index }, [
                                    _c("td", [_vm._v(_vm._s(index + 1))]),
                                    _c("td", [_vm._v(_vm._s(d.question))]),
                                    _c(
                                      "td",
                                      _vm._l(d.answers, function(answer, i) {
                                        return _c(
                                          "v-chip",
                                          {
                                            key: i,
                                            staticClass: "ma-1",
                                            class: d.correctanswers[i]
                                              ? "white--text"
                                              : "primary--text",
                                            attrs: {
                                              label: "",
                                              outlined: !d.correctanswers[i],
                                              color: d.correctanswers[i]
                                                ? "success"
                                                : "primary"
                                            }
                                          },
                                          [_c("span", [_vm._v(_vm._s(answer))])]
                                        )
                                      }),
                                      1
                                    ),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.answeremoji(
                                            _vm.sessiondetails.details[index]
                                          )
                                        )
                                      )
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.sessiondetails.details[index]
                                            ? _vm.sessiondetails.details[index]
                                                .score
                                            : 0
                                        )
                                      )
                                    ])
                                  ])
                                }),
                                0
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      290687229
                    )
                  })
                : _vm._e(),
              _c(
                "v-row",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-4",
                      attrs: { color: "secondary", large: "" },
                      on: { click: _vm.goBack }
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "arrow-left", pull: "left" }
                      }),
                      _vm._v(" Back")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }